a {
  color: inherit; /* Inherit text color from parent */
  text-decoration: none; /* Remove underline */
}

.faq-detail-container  a {
  color: #00ABD0;
}

/* Mui DatePicker Style overrides */
.MuiPickersYear-yearButton.Mui-selected {
  color: #ffffff !important; /* Selected date text color */
  background-color: #00ABD0 !important; /* Selected date background color */
}

.MuiPickersDay-daySelected:hover {
  background-color: #303f9f !important; /* Hover color */
}

/* Yet Another React Lightbox */
.yarl__slide_wrapper {
  cursor: zoom-in
}
.yarl__slide_wrapper_interactive {
  cursor: grab
}
.yarl__thumbnails {
  max-height: inherit;
}