/* SurveyJS CSS Overrides */


/* Layout overrides */

.sv-header__content {
  padding: 0;
}

.sv-header--mobile {
  padding: 0;
}

.sd-body {
  padding: 8px !important;
}

.sd-scrollable-container:not(.sd-scrollable-container--compact) {
  width: max-content;
  overflow: visible;
  max-width: 100%;
  margin: 0 auto;
}

.sd-question__header {
  text-align: center;
}

.sd-question__header--location-top {
  padding-bottom: 24px;
}

.sd-row {
  margin-bottom: 18px;
}
/**/

/* Rating Star Overides */
.sd-rating {
  min-height: initial;
}

.sd-rating__item-star {
  height: 24px;
  width: 24px;
  padding: 0 16px !important;
}

.sd-rating__item-star svg {
  height: 24px;
  width: 24px;
}

.sd-rating__item-star--selected svg {
  stroke: rgba(0, 0, 0, 0);
  fill: #EB008B;
}

.sd-rating__item-star--selected:not(.sd-rating__item-star--preview):focus-within svg {
  stroke: #EB008B;
  fill: #EB008B;
}

.sd-rating__item-star:not(.sd-rating__item-star--preview):focus-within svg {
  stroke: #EB008B;
}
/**/

/* Button Overides */
.sd-action-bar > :first-child {
  margin-right: auto;   /* Pushes all subsequent children to the right */
}

.sd-root-modern--mobile .sd-body__navigation .sv-action:not(.sv-action--hidden) {
  flex-grow: 0;
}

.sd-btn {
  border-radius: 100px;
  font-size: 0.875rem !important;
  font-weight: 500;
  padding: 7px 21px !important;
}

.sd-home-btn {
  border: #00ABD0 1px solid;
  box-shadow: none;
}

.sd-navigation__complete-btn {
  background-color: #EB008B;
}

.sd-navigation__complete-btn, .sd-btn--action:hover {
  background-color: #EB008B;
}
/**/

/* Checkbox Overides */
.sd-checkbox  .sd-selectbase__label {
  align-items: center;
  gap: 16px;
}
/**/

/* Thank You Page Overides */
.sd-completedpage {
  text-align: left;
}

.sd-completedpage:before {
  height: 0;
}

.sd-completedpage h1, .sd-completedpage h2, .sd-completedpage h3, .sd-completedpage h4, .sd-completedpage h5, .sd-completedpage h6 {
  margin: 0;
  margin-bottom: "1rem";
}

/**/